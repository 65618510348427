import React from 'react'
import LayoutComponent from '../../../components/documentation-layout/Layout'

const DeleteTestCase = () => {
  return (
    <LayoutComponent>
      <h1>Deleting Test Cases</h1>

      <p>To keep your test library clean and tidy, you can delete old or unnecessary test cases. In order to delete a test case, you must first archive it from the test library.</p>

      <div class="testimonials">
        <h3>Important</h3>
        <p>You won't be able to delete test cases if they are part of a test cycle or have other dependencies, such as being used in another component. You can only delete one page of test cases at a given time. This is to limit the impact of the action on your instance.</p>
      </div>

      <h2>Step-by-step</h2>

      <p>First, you must archive a test case to delete it.</p>

      <ol>
        <li>Go to the <strong>Archived test cases</strong> section in the test library.</li>
        <li>Select the test cases that you'd like to delete (max one page at a time).</li>
        <li>Click the delete button to start the deletion process.</li>
        <li>
          The first step of the process identifies any test cases with dependencies (included in a test cycle or another component) and informs you that they will not be included in the deletion process. You'll need to either remove the test cases from a cycle or from being reused in another test case to delete them.
        </li>
        <li>
          If your test cases don't have any dependencies, you'll see this message.
        </li>
        <li>
          The next step will show the number of test cases selected for deletion and ask you to confirm that you're sure you want to proceed.
        </li>
        <li>
          The deletion process may take some time depending on the number of test cases selected. This is due to checking and deleting all the data related to the test case.
        </li>
        <li>
          Once the process is complete, you'll see an <strong>Acknowledgement</strong> which will show you the number of test cases that have been successfully deleted, and those that have been skipped (with dependencies).
        </li>
        <li>The process is complete!</li>
      </ol>
    </LayoutComponent>
  )
}

export default DeleteTestCase